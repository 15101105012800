const CONSTANTS = {
    ENTITY_GOALS: {
            EAT: 'eat',
            ESCAPE: 'escape',
            ATTACK: 'attack',
            NONE: 'none'
        },
    
    SCREEN_WIDTH: 1000,
    SCREEN_HEIGHT: 700,
    
    DIRECTIONS: {
        EAST: 0,
        WEST: 1
    },

    ENTITY_STATES: {
            IDLE: 'IDLE',
            WALK: 'WALKING',
            DYING: 'DYING',
            ATTACK: 'ATTACKING',
            DEAD: 'DEAD'
        },

    SMART_OBJECT: {
        IS_EDIBLE: 'is_edible', //can be eaten for HP
        IS_PRETTY: 'is_pretty', //can be traded
        IS_VALUABLE: 'is_valuable', //can be traded
        IS_DANGEROUS: 'is_dangerous', //approach cautiously
        IS_BLOCKING: 'is_blocking', //cannot be crossed
        IS_CARRYABLE: 'is_carryable' //can be put in inventory
        },

    TYPES_TO_IMAGES: {
        SKULL: 'skull.png',
        PLANT: 'plant.png',
        ORE: 'ore.png',
        PEOPLEROCKS: 'peoplerocks.png',
        GRIFFIN: 'griffin',
        WYVERN: 'wyvern'
        },

    ENTITIES_LIST: [
        { s_type: 'SKULL', e_type: 'SmartObj', QP: ['IS_EDIBLE', 'IS_CARRYABLE'], initHP: 2, height: 20, width: 41 },
        { s_type: 'PLANT', e_type: 'SmartObj', QP: ['IS_EDIBLE', 'IS_CARRYABLE', 'IS_PRETTY'], initHP: 2, height: 25, width: 25 },
        { s_type: 'ORE', e_type: 'SmartObj', QP: ['IS_VALUABLE', 'IS_CARRYABLE'], initHP: 2, height: 25, width: 25 },
        { s_type: 'PEOPLEROCKS', e_type: 'SmartObj', QP: ['IS_VALUABLE', 'IS_CARRYABLE'], initHP: 2, height: 44, width: 64 },
        { s_type: 'GRIFFIN', e_type: 'Entity', QP: ['IS_DANGEROUS', 'IS_BLOCKING'], initHP: 4, height: 46, width: 192, sight: 2, speed: .2, 
            priorities: [{name: 'SURVIVAL', score: 3}, {name: 'AGGRESSION', score: 5}, {name: 'FOOD', score: 2}, {name: 'GREED', score: 1}] },
        { s_type: 'WYVERN', e_type: 'Entity', QP: ['IS_DANGEROUS', 'IS_BLOCKING'], initHP: 3, height: 54, width: 81, sight: 1, speed: .5,
            priorities: [{name: 'SURVIVAL', score: 2}, {name: 'AGGRESSION', score: 3}, {name: 'FOOD', score: 4}, {name: 'GREED', score: 1}] },
        { s_type: 'SATYR', e_type: 'Entity', QP: ['IS_DANGEROUS', 'IS_BLOCKING'], initHP: 3, height: 58, width: 44, sight: 1, speed: .3,
            priorities: [{name: 'SURVIVAL', score: 2}, {name: 'AGGRESSION', score: 3}, {name: 'FOOD', score: 4}, {name: 'GREED', score: 1}] }
    ]
};

export default CONSTANTS;