import React from 'react';
import { Sprite } from '@pixi/react';



function BackgroundSprite({ containerInfo }) {
  

    let curHr = new Date().getHours();

    let hrRotation = parseInt(curHr*15);  

    //add birds ?
  
    return (
        <>
            
            <Sprite anchor={0.5} scale={1} angle={hrRotation} x={500} y={50} image="/sun_and_moon.png" />
            <Sprite anchor={0} scale={1} x={0} y={0} image="/background-flat.png" />
        </>
        
    ) 
}

export default BackgroundSprite