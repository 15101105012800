import React, { useState, useEffect, useRef } from 'react';
import { AnimatedSprite, Sprite } from '@pixi/react';
import * as PIXI from "pixi.js";
import CONSTANTS from './constants';
import MASTER_SHEET from '../sheets/masterSheet.json';

function EntitySprite({ entType, queriProps, x, scale, entState=CONSTANTS.ENTITY_STATES.IDLE, initialProps={}, direction }) {
    //this represents a "business-logic" level entity in the game that has intelligence, animations and movement

    //entState = current state of entity - passed in so we can set it from parent
    //initialProps passes camoflague, hitPoints start, sight value, anything that is static or needs to be changed from parent

    //holds textures based on state - IDLE, WALK, ATTACK, DYING, DEAD
    //{ IDLE: [], WALK: [], ATTACK: [], DYING: [], DEAD: [one frame] }
    let [ myTextures, setMyTextures ] = useState([]);

    let myRef = useRef(null);

    let manipScale = {x: scale, y: scale};

    if(direction === CONSTANTS.DIRECTIONS.EAST){
        manipScale.x = scale*-1;
    }

    let masterURl = '/res/'  + entType.toUpperCase() + '/';
    const deadIMG = `/res/${entType.toUpperCase()}/${entType.toUpperCase()}_DEAD.png`;


    const loadAnimations = (entType) => {

      const getTexturesFromArray = (framesSet) => {
        if(framesSet.length > 0) {
          return framesSet.map(frame => {
            return PIXI.Texture.from(masterURl + frame);
          })
        }

        return [];
        
      }

      let theFrameWork = {};
      Object.keys(MASTER_SHEET[entType.toUpperCase()]).forEach( (thisKey) => {
        theFrameWork[thisKey] = getTexturesFromArray(MASTER_SHEET[entType.toUpperCase()][thisKey]);
      })
      return theFrameWork;
    }

    useEffect(() => {
          setMyTextures(loadAnimations(entType));
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      useEffect( () => {
        if(myRef && myRef.current) {
          if(myRef.current.playing === false) {
            myRef.current.play();
          };
        }

      }, [entState])

    if(Object.keys(myTextures).length < 1) {
      return null
    }



    return (
        entState !== CONSTANTS.ENTITY_STATES.DEAD ?
          <AnimatedSprite
              ref={myRef}
              animationSpeed={0.3}
              isPlaying={true}
              textures={myTextures[entState]}
              x={x}
              y={95}
              anchor={1} 
              scale={manipScale} />
        :
        <Sprite
            image={deadIMG}
            scale={manipScale}
            x={x}
            y={95}
            anchor={1} />
    )
}

export default EntitySprite