/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-unused-vars */
import CONSTANTS from "./constants";
import Utilities from "./Utilities";
import AIEngine from './AIEngine';

export default {

    checkLayerContext: (lc, subj, extOfPxVw) => {
        //return any entities or smartObjects within the extent of view
        return lc.filter( (thisObj) => {
            if(subj.direction === CONSTANTS.DIRECTIONS.WEST) {
                return thisObj.x <= extOfPxVw.end && thisObj.x >= extOfPxVw.start-thisObj.width;
            } else {
                return (thisObj.x + thisObj.width >= extOfPxVw.start) && (thisObj.x <= extOfPxVw.end+thisObj.width);
            }
        });
    },

    makeADecision: (thisSprite, otherSprite) => {
        //make a decision
        //look at health / priorities

        // if(Object.keys(thisSprite.memory).length > 0) {

        // }

        const topPriority  = AIEngine.checkPriorities(thisSprite);

        switch (topPriority.name) {
            case 'SURVIVAL':
                //get away from other entities
                AIEngine.takeAction("RUN_AWAY", thisSprite, otherSprite);
                break;
            
            case 'AGGRESSION':
                //attack or find other entities to attack
                AIEngine.takeAction("ATTACK", thisSprite, otherSprite);
                break;

            case 'FOOD':
                //eat or find food
                AIEngine.takeAction("EAT", thisSprite, otherSprite);
                break;

            case 'GREED':
                //pick up smart objects with value or find more smart objects 
                //AIEngine.takeAction('PICKUP', thisSprite, otherSprite)
                break;

            default:
                break;
        }

    },

    processCollisionPairs: (collisionPairsList) => {
        //given the list of pairs, tell each how to react (if entity, if smartobj, just TAKE IT)
        for(let curPair=0; curPair < collisionPairsList.length; curPair++) {
            let sprite1 = collisionPairsList[curPair].first;
            let sprite2 = collisionPairsList[curPair].second;

            if(sprite1.e_type === 'Entity') {

                AIEngine.makeADecision(sprite1, sprite2);
            }

            if(sprite2.e_type === 'Entity') {
                let spritesHealthPct = sprite2.initHP / sprite2.currentHP * 100;
                let topPriority = AIEngine.checkPriorities(sprite2);

                AIEngine.makeADecision(sprite2, sprite1);
            }
        }

        //return modified sprites to be merged back into list
        return collisionPairsList;
    },

    faceTarget: (turner, toTurnTo)  => {
        if(turner.x < toTurnTo.x && turner.direction === CONSTANTS.DIRECTIONS.WEST){
            turner.direction = CONSTANTS.DIRECTIONS.EAST;
            return;
        }
        
        if(turner.x > toTurnTo.x && turner.direction === CONSTANTS.DIRECTIONS.EAST){
            turner.direction = CONSTANTS.DIRECTIONS.WEST;
            return;
        }
    },

    turnAwayFromTarget: (turner, toTurnAway) => {
        if(turner.x < toTurnAway.x && turner.direction === CONSTANTS.DIRECTIONS.EAST){
            turner.direction = CONSTANTS.DIRECTIONS.WEST;
            return;
        }
        
        if(turner.x > toTurnAway.x && turner.direction === CONSTANTS.DIRECTIONS.WEST){
            turner.direction = CONSTANTS.DIRECTIONS.EAST;
            return;
        }
    },

    takeAction: (ActType, src, target) => {
        switch (ActType) {
            case 'EAT':
                AIEngine.faceTarget(src, target);

                if(target.s_type !== 'SMART_OBJECT' && target.entState !== "DEAD") {
                    //counts as an attack
                    target.memory = { type: 'ATTACK', src: src };
                }
                if(target.curentHP < 1) {
                    target.entState = CONSTANTS.ENTITY_STATES.IDLE;
                    return;
                }
                src.entState = CONSTANTS.ENTITY_STATES.ATTACKING;
                src.currentHP = src.currentHP < src.maxHP ? src.currentHP + 1: src.currentHP;
                target.hp =-1;
                break;
            case 'RUN_AWAY':
                AIEngine.turnAwayFromTarget(src, target);
                src.entState = CONSTANTS.ENTITY_STATES.WALK;
                break;
            default:
                //attack
                AIEngine.faceTarget(src, target);
                src.entState = CONSTANTS.ENTITY_STATES.ATTACK;
                if(target.curentHP < 1) {
                    src.entState = CONSTANTS.ENTITY_STATES.WALK;
                    return;
                }
                target.hp =- 1;
                if(target.e_type === 'Entity'){
                    target.memory = { type: 'ATTACK', src: src };
                }
                
                break;
        }
    },

    spriteIsNextTo: (checkingFor, checkingOn) => {
        return Utilities.collision(checkingFor, checkingOn);
    },

    getTargetsInView: (who, sprList) => {
        let startX = who.direction === CONSTANTS.DIRECTIONS.WEST ? who.x+who.width : who.x - (who.sight * who.width);
            let endX = who.direction === CONSTANTS.DIRECTIONS.WEST ? startX + (who.sight * who.width) : who.x;

            if(startX < 0) {
                startX = 0;
            }

            if(startX > CONSTANTS.SCREEN_WIDTH) {
                startX = CONSTANTS.SCREEN_WIDTH;
            }

            if(endX < 0) {
                endX = 0;
            }

            if(endX > CONSTANTS.SCREEN_WIDTH) {
                endX = CONSTANTS.SCREEN_WIDTH;
            }

            const extentOfPixInView = { 
                start: startX,
                end: endX
            };

            return AIEngine.checkLayerContext(sprList, who, extentOfPixInView);
    },

    checkPriorities: (thisSpr) => {
        //priorities: [{name: '', score: 1-5}]
        if(thisSpr.e_type !== 'SmartObj' && thisSpr.entState !== 'DEAD'){
            let highestScore = thisSpr.priorities.sort((a,b) => { return b.score - a.score });
            return highestScore[0];
        }
        
        return '';
    },

    processList: (sprList, scaleToUse) => {
        

        //change below to process sprites not in collision
        for(let thisSpr = 0 ; thisSpr < sprList.length; thisSpr++) {

            if(sprList[thisSpr].currentHP <= 0 ) {
                if(sprList[thisSpr].e_type !== 'SmartObj'){
                    sprList[thisSpr].entState = 'DYING';
                } else {
                    sprList.splice(thisSpr, 1);
                }
                
                continue; //don't do anything else this iteratioon
            }

            //check if dead, if dead, delete
            // if(sprList[thisSpr].e_type !== 'SmartObj' && sprList[thisSpr].entState === 'DEAD' ) {
            //     sprList.splice(thisSpr, 1);
            //     continue; //don't do anything else this iteratioon
            // }

            if(sprList[thisSpr].e_type === 'SmartObj' && sprList[thisSpr].hp <=0 ) {
                sprList.splice(thisSpr, 1);
                continue; //don't do anything else this iteratioon
            }

            if(sprList[thisSpr].e_type !== 'SmartObj' && sprList[thisSpr].entState === 'DYING') {
                sprList[thisSpr].entState = 'DEAD';
                continue; //don't do anything else this iteratioon
            }

            //switch directions if at edge
            if(sprList[thisSpr].e_type !== 'SmartObj' && sprList[thisSpr].entState === CONSTANTS.ENTITY_STATES.WALK) {
                
            //if we are at the edge of the screen, turn around
                if(sprList[thisSpr].x <= 0 && sprList[thisSpr].direction === CONSTANTS.DIRECTIONS.EAST){
                    sprList[thisSpr].direction = CONSTANTS.DIRECTIONS.WEST;
                }

                if((sprList[thisSpr].x + sprList[thisSpr].width) >= CONSTANTS.SCREEN_WIDTH && sprList[thisSpr].direction === CONSTANTS.DIRECTIONS.WEST) {
                    sprList[thisSpr].direction = CONSTANTS.DIRECTIONS.EAST;
                }

                let calcSpeed = sprList[thisSpr].speed;

                sprList[thisSpr].x = sprList[thisSpr].direction === CONSTANTS.DIRECTIONS.WEST ? sprList[thisSpr].x + calcSpeed : sprList[thisSpr].x - calcSpeed;
                
            }

            let collidedSprites = Utilities.getCollisionsPairs(sprList, scaleToUse);


            AIEngine.processCollisionPairs(collidedSprites);
            
            

            //check memory
            // if(Object.keys(sprList[thisSpr].memory) > 0) {
            //     //recent event
            //     respondToMemory(sprList[thisSpr])
            // }

            //check priorities if no urgent event
            //checkPriorities(sprList[thisSpr]);

            //what's important to me?

            if(sprList[thisSpr].e_type !== 'SmartObj' && sprList[thisSpr].entState === 'IDLE') {
                //start out just changing to walk
                sprList[thisSpr].entState = 'WALKING';
            }
        }


        return sprList;
    }
}