import React from 'react';
import useScreenSize from '../hooks/UseScreenSize';
import { Stage } from '@pixi/react';
import LayerContainer from '../game/LayerContainer';
import CONSTANTS from '../game/constants';

function HomePage() {

    const screenSize = useScreenSize();

    return (
        <>
        <img src="/biglogo.png" alt="OOPP" /><br/>
        {
            screenSize.width > 500 ?
                <Stage options={{ backgroundColor: "#f3eadb"}} width={CONSTANTS.SCREEN_WIDTH} height={CONSTANTS.SCREEN_HEIGHT}>
                    <LayerContainer distance={0} isBG={true}>
                        {/* background with sun/moon here */}
                        
                    </LayerContainer>
                    <LayerContainer distance={1}>

                    </LayerContainer>
                    <LayerContainer distance={2}>

                    </LayerContainer>
                    <LayerContainer distance={3}>

                    </LayerContainer>
                    <LayerContainer distance={4}>

                    </LayerContainer>
                    <LayerContainer distance={5}>

                    </LayerContainer>
                    <LayerContainer distance={6}>
                        
                    </LayerContainer>

                </Stage>
            :
            <div className='mobile-view'>
                
            </div>
        }
        </>
    )
}

export default HomePage