import * as React from "react";
import * as ReactDOM from "react-dom/client";
import './App.css';
import HomePage from './pages/HomePage';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Exegesis from "./pages/Exegesis";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      exact: true,
      element: <HomePage />,
    },
    {
      path: "/exegesis",
      element: <Exegesis />
    }
  ]);
  
  ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}

export default App;
