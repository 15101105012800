import React from 'react';
import { Sprite } from '@pixi/react';
import CONSTANTS from './constants';

function SmartObj({ objtype, queriProps, initHP, initialX, scale, direction }) {
  
    let spriteImg = '/res/' + CONSTANTS.TYPES_TO_IMAGES[objtype.toUpperCase()];

    let manipScale = {x: scale, y: scale};

    if(direction === CONSTANTS.DIRECTIONS.EAST){
        manipScale.x = scale*-1;
    }

    return (
    <Sprite
        x={initialX}
        y={95}
        anchor={1} 
        scale={manipScale}
        image={spriteImg} />
  )
}

export default SmartObj