/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-unused-vars */
import CONSTANTS from "./constants";
import Utilities from './Utilities';

export default {
    collision: (rect1, rect2, scale=1) => {
        return (
            rect1.x < rect2.x + (rect2.width*scale) &&
            rect1.x + (rect1.width*scale) > rect2.x
        )
        
    },

    getCollisionsPairs : (sprList, scaleToUse) => {


        const findThisPairInEntry =  (spr1, spr2, listToSearch) => {
            //return if spr1 and spr2 appear in either position this list
            let filteredList = listToSearch.filter( (thisItem) => {
                return  !(thisItem.first.e_type === 'SmartObj' && thisItem.second.e_type === 'SmartObj') &&
                        (thisItem.first.id === spr1.id || thisItem.first.id === spr2.id ) && 
                        ( thisItem.second.id === spr1.id || thisItem.second.id === spr2.id )
            });
    
            return filteredList.length > 0;
        };

        //returns pairs of sprites in collision
        let collidedPairsToReturn = [];
        let otherSprites = [];
        let cpySprList = [...sprList];

        //if a collision is detected, remove both sprites bc we don't need to check the second
        for(let x=0; x<cpySprList.length-1; x++){
            for(let y=x+1; y<cpySprList.length; y++) {
                if(!findThisPairInEntry(cpySprList[x], cpySprList[y], collidedPairsToReturn)) {
                    if(Utilities.collision(cpySprList[x], cpySprList[y], scaleToUse)){
                        collidedPairsToReturn.push({first: cpySprList[x], second: cpySprList[y]});
                    }
                }
            }
        }

        return collidedPairsToReturn;
    },

    createFromTemplate: (sprInfo, index, startingX) => {
        return {
            id: sprInfo.s_type + '_' + index,
            x: startingX,
            ...sprInfo.otherProperties,
            entState: CONSTANTS.ENTITY_STATES.IDLE,
            direction: Math.round(Math.random()),
            memory: {},
            priorities: []
        }
    },

    decideNextMove: (who, layerContext, evtsHappened) => {
        //takes in a sprite, layerContext, and eventsJustHappened
        //given the context, returns a state for the said sprite

        const checkLayerContext = (lc, subj, extOfPxVw) => {
            //return any entities or smartObjects within the extent of view
            return lc.filter( (thisObj) => {
                if(subj.direction === CONSTANTS.DIRECTIONS.WEST) {
                    return thisObj.x <= extOfPxVw.end && thisObj.x >= extOfPxVw.start-thisObj.width;
                } else {
                    return (thisObj.x + thisObj.width >= extOfPxVw.start) && (thisObj.x <= extOfPxVw.end+thisObj.width);
                }
            });
        }

        //first let's look at what just happened / our current state
        switch(who.entState) {
            case CONSTANTS.ENTITY_STATES.WALK:
                //if we are at the edge of the screen, turn around
                if(who.x <= 0 && who.direction === CONSTANTS.DIRECTIONS.WEST){
                    who.direction = CONSTANTS.DIRECTIONS.EAST;
                }

                if((who.x+who.width) >= CONSTANTS.SCREEN_WIDTH && who.direction === CONSTANTS.DIRECTIONS.EAST) {
                    who.direction = CONSTANTS.DIRECTIONS.WEST;
                }



                break;

            default:
                //CONSTANTS.ENTITY_STATES.IDLE

                break;
        }


        //second, test sight to see what is in my sights
        if(who.sight) {
            let startX = who.direction === CONSTANTS.DIRECTIONS.WEST ? who.x+who.width : who.x - (who.sight * who.width);
            let endX = who.direction === CONSTANTS.DIRECTIONS.WEST ? startX + (who.sight * who.width) : who.x;

            if(startX < 0) {
                startX = 0;
            }

            if(startX > CONSTANTS.SCREEN_WIDTH) {
                startX = CONSTANTS.SCREEN_WIDTH;
            }

            if(endX < 0) {
                endX = 0;
            }

            if(endX > CONSTANTS.SCREEN_WIDTH) {
                endX = CONSTANTS.SCREEN_WIDTH;
            }

            const extentOfPixInView = { 
                start: startX,
                end: endX
            };

            let targets = checkLayerContext(layerContext, who, extentOfPixInView);

            //check to see if something just happened
            if(who.event.length > 0) {


                //pop event off
                who.event.splice(0,1);
            }
        }
    },

    processGoaps: (who, wholeInfo) => {

    }
};