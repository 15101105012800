import React, {useState, useEffect} from 'react';
import { Container, useTick } from '@pixi/react';
import BackgroundSprite from './BackgroundSprite';

import CONSTANTS from './constants';
import SmartObj from './SmartObj';
import EntitySprite from './EntitySprite';
import AIEngine from './AIEngine';

function LayerContainer({ distance = 0, height = 100, isBG=null, children }) {

    const [ mySprites, setMySprites ] = useState([]);
    const positionX = 0;
    
    
    const scaleForChildren = 0.4+(distance*0.1);
    const positionY = ((scaleForChildren*height)*distance);
    // const addSprite = (newSpriteObj) => {
    //     let myArrCopy = [...mySprites];
    //     myArrCopy.push(newSpriteObj);

    //     setMySprites(myArrCopy);
    // }

    // const removeSprite = (spriteID) => {
    //     let myArrCopy = [...mySprites];

    //     let sprIndex = myArrCopy.findIndex( (thisItem) => {
    //         return thisItem.id === spriteID;
    //     })

    //     if(sprIndex > -1) {
    //         myArrCopy.splice(sprIndex, 1);
    //     }

    //     setMySprites(myArrCopy);
    // }

    // const checkCollisions = () => {
    //     //iterate through mySprites, comparing each to it's following items
    //     let curItem;
    //     for(let x=0; x<mySprites.length-1; x++){
    //         for(let y=x+1; y<mySprites.length; y++) {
    //             if(Utilities.collision(mySprites[x], mySprites[y], scaleForChildren)){
    //                 //using the width and height specified by the sprite constants and the layer distance
    //                 //to compute real size
    //                 //do something with information on one or both sprites
    //                 console.log('sprite1: ', mySprites[x], ' sprite2: ', mySprites[y], ' collided')
    //             }
    //         }
    //     }
    // }

    const allocateSpritesAndSmObjs = () => {
        let allocArray = [];
        let numToCreate = Math.ceil((Math.random()*10)+3);
        for(let thisItem=1; thisItem <= numToCreate; thisItem++){
            let randItem = Math.floor((Math.random()*CONSTANTS.ENTITIES_LIST.length));
            //it's ok for entitySprites and SmartObjects to collide, but not entities and entities
            let xForItem = Math.ceil((Math.random()*900));
            allocArray.push({
                id: CONSTANTS.ENTITIES_LIST[randItem].s_type + '_' + thisItem,
                x: xForItem,
                ...CONSTANTS.ENTITIES_LIST[randItem],
                entState: 'IDLE',
                direction: Math.round(Math.random()), //randomly assign direction - West:1, East: 0
                memory: {},
                currentHP: CONSTANTS.ENTITIES_LIST[randItem].initHP
            });

            
        }

        setMySprites(allocArray);

    }

    useEffect( ()=> {
        allocateSpritesAndSmObjs();
    },[])

    useTick( () => {
        let copySpr = [...mySprites];
        let changed = AIEngine.processList(copySpr, scaleForChildren);
       setMySprites(changed);
    });

    return (
        <Container
            position={[positionX, positionY]} >
            { 
                isBG && isBG === true ?
                    <BackgroundSprite containerInfo={{positionX, positionY}} />
                :
                    null
            } 
            {
                mySprites.map( (thisSpr) => {
                    if(thisSpr.e_type === 'SmartObj') {
                        return (
                            <SmartObj 
                                objtype={thisSpr.s_type} 
                                queriProps={thisSpr.QP} 
                                direction={thisSpr.direction}
                                initHP={thisSpr.initHP} 
                                initialX={thisSpr.x} 
                                scale={scaleForChildren} />);
                    }

                    return (
                        <EntitySprite 
                            entType={thisSpr.s_type} 
                            entState={thisSpr.entState} 
                            queriProps={thisSpr.QP} 
                            initialProps={{HP: thisSpr.initHP}} 
                            direction={thisSpr.direction}
                            x={thisSpr.x} 
                            scale={scaleForChildren} />);
                    
                })
            }
        </Container>
    )
}

export default LayerContainer