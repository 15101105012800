import React from 'react'

function Exegesis() {
  return (
    <div className="page exegesis">

    </div>
  )
}

export default Exegesis